import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import './styles.scss'

const Error = loadable(() => import('/src/components/form-components/Error'))
const Required = loadable(() =>
    import('/src/components/form-components/Required/Required')
)

const FieldSet = ({
    variant,
    legend,
    groupName,
    required,
    className,
    children
}) => {
    return (
        <fieldset
            className={`c-fieldset${variant && ` c-fieldset--${variant}`}${
                className && ` ${className}`
            }`}
        >
            {legend && (
                <legend>
                    {legend}
                    {required && <Required />}
                </legend>
            )}

            <div className="c-fieldset__inner">{children}</div>

            <Error name={groupName} />
        </fieldset>
    )
}

FieldSet.propTypes = {
    /**
     * Fieldset variant
     */
    variant: PropTypes.oneOf(['wrap', 'column']),
    /**
     * Fieldset legend title
     */
    align: PropTypes.oneOf(['centered']),
    /**
     * Fieldset legend title
     */
    legend: PropTypes.string,
    /**
     * Checkbox or radio button group name
     */
    groupName: PropTypes.string,
    /**
     * Whether the fields in this fieldset are required
     */
    required: PropTypes.bool,
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired
}

FieldSet.defaultProps = {
    groupName: 'consent',
    children: '<p>Fieldset content goes here</p>'
}

export default FieldSet
